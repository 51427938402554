import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "../styles/GlobalStyle";
import { AnimatePresence } from "framer-motion";

import { Helmet } from "react-helmet";

import "../styles/reset.css";
import Cookies from "./Cookies";
import Footer from "./Footer";
import Navbar from "./Navbar";

export const Content = styled.section`
  overflow-x: hidden;
  padding-top: 128px;
  margin-top: -128px;
`;

export default function Layout({ children, taxdashs, comprar }) {
  const [cookies, setCookies] = useState(false);

  useEffect(() => {
    setCookies(localStorage.getItem("cookies") === "true");
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookies", true);
    setCookies(true);
  };

  const rejectCookies = () => {
    localStorage.setItem("cookies", false);
    setCookies(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="icon" href="/taxcel-icon.ico" />{" "}
        {/*------- jivochat ---------*/}
        {/* <script
          type="text/javascript"
          src="//code.jivosite.com/widget/1fy7GwU0IC"
          async
        ></script>
        <script type="text/javascript">
          {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:372985,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script> */}
        {/* ---------------------- */}
        {/*------- hotjar ---------*/}
        <script>
          {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2619666,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
        {/* ---------------------- */}
        <script type="text/javascript">
          {`(function () {
        var options = {
            whatsapp: "+5511964042036",
            whatsapp_color: "#3CFF96",
            company_logo_url: "https://taxceladdins.com.br/icons/icon-256x256.png",
            greeting_message:
              "Olá! Gostaria de mais informações sobre o TaxSheets (Editor de SPED)",
            call_to_action: "Estamos online no WhatsApp",
            position: "left",
        };
        var proto = document.location.protocol,
            host = "getbutton.io",
            url = proto + "//static." + host
          var s = document.createElement("script")
          s.type = "text/javascript"
          s.async = true
          s.src = url + "/widget-send-button/js/init.js"
          s.onload = function() {
            WhWidgetSendButton.init(host, proto, options)
            var whatsapp_button = document.getElementsByClassName("wh-widget-button wh-widget-button-activator")
            whatsapp_button[0].onclick = function() {
              ga("send", "event", "WhatsApp", "whatsappclick")
            }
          }
          var x = document.getElementsByTagName("script")[0]
          x.parentNode.insertBefore(s, x)
        })()`}
        </script>
        {/* <!-- Start of HubSpot Embed Code --> */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/46590252.js"
        ></script>
        {/* <!-- End of HubSpot Embed Code --> */}
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-959950554"
          type="text/javascript"
        >
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'AW-959950554');
            `}
        </script> */}
      </Helmet>
      <GlobalStyle />
      <Navbar taxdashs={taxdashs} comprar={comprar} />
      <Content>{children}</Content>
      <Footer taxdashs={taxdashs} />
      <AnimatePresence>
        {!cookies && (
          <Cookies
            rejectCookies={rejectCookies}
            acceptCookies={acceptCookies}
          />
        )}
        <script
          type="text/javascript"
          async
          src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/54da261f-7ae5-4ba4-87c7-fb85ab0471b4-loader.js"
        ></script>
      </AnimatePresence>
    </ThemeProvider>
  );
}
